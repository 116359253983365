import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import logo from "../assets/images/header/logo-vector.svg";
import MediaQuery from "react-responsive";
import DS from "../assets/images/header/DS.svg";
/* TODO:
- fix logo size
- add hover active
-  */
function Header() {
  const [collapse, setCollapse] = useState(true);
  function toggle() {
    setCollapse(!collapse);
  }
  return (
    <div>
      <MediaQuery minWidth={978}>
        <Navbar
          type="light"
          theme="white"
          expand="md"
          fixed="top"
          style={styles.navbar}
        >
          <NavbarBrand href="#Home">
            <img style={styles.logo} src={logo} alt="Diana Serra"></img>
          </NavbarBrand>
          <Nav navbar>
            <NavItem>
              <NavLink active href="#Skills">
                Technical Skills
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#Experience">
                Education & Experience
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#Community">
                Community & Leadership
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Hobbies">Hobbies & Special Interests</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Contact">Contact Me</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </MediaQuery>
      <MediaQuery maxWidth={977}>
        <Navbar
          type="light"
          theme="white"
          expand="md"
          fixed="top"
          style={styles.navbarMobile}
        >
          <MediaQuery minWidth={351}>
            <NavbarBrand href="#Home">
              <img style={styles.logo} src={logo} alt="Diana Serra"></img>
            </NavbarBrand>
          </MediaQuery>
          <MediaQuery maxWidth={350}>
            <NavbarBrand href="#Home">
              <img style={styles.logoMobile} src={DS} alt="Diana Serra"></img>
            </NavbarBrand>
          </MediaQuery>
          <Nav navbar>
            <Button
              outline
              theme="dark"
              active={!collapse}
              onClick={() => toggle()}
            >
              Menu
            </Button>
          </Nav>
        </Navbar>
        <Collapse open={!collapse} style={styles.collapse}>
          <ListGroup>
            <ListGroupItem style={styles.dropdown}>
              <NavLink active href="#Skills" style={styles.dropdownItem}>
                Technical Skills
              </NavLink>
            </ListGroupItem>
            <ListGroupItem style={styles.dropdown}>
              <NavLink active href="#Experience" style={styles.dropdownItem}>
                Education & Experience
              </NavLink>
            </ListGroupItem>
            <ListGroupItem style={styles.dropdown}>
              <NavLink active href="#Community" style={styles.dropdownItem}>
                Community & Leadership
              </NavLink>
            </ListGroupItem>
            <ListGroupItem style={styles.dropdown}>
              <NavLink href="#Hobbies" style={styles.dropdownItem}>
                Hobbies & Special Interests
              </NavLink>
            </ListGroupItem>
            <ListGroupItem style={styles.dropdown}>
              <NavLink href="#Contact" style={styles.dropdownItem}>
                Contact Me
              </NavLink>
            </ListGroupItem>
          </ListGroup>
        </Collapse>
      </MediaQuery>
    </div>
  );
}

const styles = {
  logo: {
    maxHeight: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  logoMobile: {
    maxHeight: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  collapse: {
    position: "fixed",
    zIndex: "1029",
    marginTop: "69px",
    width: "100%",
  },
  navbarMobile: {
    flexDirection: "row",
    height: "69px",
    justifyContent: "space-between",
  },
  navbar: { height: "69px" },
  dropdown: { backgroundColor: "#212529", opacity: "0.995" },
  dropdownItem: { color: "#FFFFFF" },
};
export default Header;
