import avatar from "../assets/images/home/Avatar.svg";
import bubble from "../assets/images/home/speech-bubble.svg";
import MediaQuery from "react-responsive";

function Home() {
  return (
    <div id="Home" style={styles.container}>
      <MediaQuery minWidth={480}>
        <div style={styles.innerContainer}>
          <img
            src={avatar}
            style={styles.avatar}
            alt="cartoon of Diana smiling and waving"
          ></img>
          <MediaQuery minWidth={800}>
            <div style={styles.bubbleContainer}>
              <img src={bubble} style={styles.bubble} alt="speech bubble"></img>
              <div style={styles.helloContainer}>
                <div style={styles.helloText}>
                  <p style={styles.helloTitle}>Hey there, I'm Diana-</p>
                  <p style={styles.hello}>
                    web developer, McGill alumna, and advocate for diversity in
                    engineering. I’m passionate about creating accessible web
                    tools for all, and at the end of the day all I want is to
                    proudly point to my work and say “look what I made!”
                  </p>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={799}>
            <div style={styles.helloContainerMobile}>
              <div style={styles.helloTextMobile}>
                <p style={styles.helloTitle}>Hey there, I'm Diana-</p>
                <p style={styles.hello}>
                  web developer, McGill alumna, and advocate for diversity in
                  engineering. I’m passionate about creating accessible web
                  tools for all, and at the end of the day all I want is to
                  proudly point to my work and say “look what I made!”
                </p>
              </div>
            </div>
          </MediaQuery>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={479}>
        <div style={styles.innerContainerSmallScreen}>
          <img
            src={avatar}
            style={styles.avatarSmallScreen}
            alt="cartoon of Diana smiling and waving"
          ></img>
          <div style={styles.helloContainerMobile}>
            <div style={styles.helloTextMobile}>
              <p style={styles.helloTitle}>Hey there, I'm Diana-</p>
              <p style={styles.hello}>
                web developer, McGill alumna, and advocate for diversity in
                engineering. I’m passionate about creating accessible web tools
                for all, and at the end of the day all I want is to proudly
                point to my work and say “look what I made!”
              </p>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#E85A4A",
    paddingTop: "69px", //should be 88px when screen is medium size (<977 wide)
    marginTop: "-69px",
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  avatar: {
    marginTop: "30px",
    marginBottom: "30px",
    maxHeight: "220px",
    paddingLeft: "20px",
    width: "30%",
  },
  bubbleContainer: {
    position: "relative",
  },
  bubble: {
    height: "200px",
    width: "auto",
  },
  helloContainer: {
    position: "absolute",
    bottom: "-30px",
    left: "0",
    right: "0",
    height: "300px",
    overflow: "hidden",
    width: "100%",
  },
  helloText: {
    color: "black",
    position: "absolute",
    width: "80%",
    top: "50%",
    left: "53%",
    WebkitTransform: "translate(-50%, -37%)",
    transform: "translate(-50%, -37%)",
  },
  helloTitle: {
    fontSize: "20px",
    fontWeight: "bolder",
    textAlign: "initial",
  },
  hello: {
    fontSize: "16px",
    textAlign: "initial",
  },
  helloContainerMobile: {
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    marginRight: "20px",
    marginLeft: "10px",
    borderRadius: "5px",
  },
  helloTextMobile: {
    color: "black",
    margin: "15px",
  },
  avatarSmallScreen: {
    maxHeight: "220px",
    paddingLeft: "20px",
    width: "40%",
    marginBottom: "-2px",
  },
  innerContainerSmallScreen: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
};
export default Home;
