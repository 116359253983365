import { Timeline } from "antd";
import MediaQuery from "react-responsive";

function Experience() {
  return (
    <div id="Experience" style={styles.experience}>
      <div style={styles.experienceContainer}>
        <h1 style={styles.title}>Education and Experience</h1>
        <MediaQuery minWidth={955}>
          <Timeline mode="alternate">
            <Timeline.Item position="right" label="Graduated Dec 2020">
              <svg
                width="451"
                height="80"
                viewBox="0 0 451 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H414.281H450.5L414.281 33V79.1654H0V0Z"
                  fill="white"
                />
                <text fill="black" x="20px" y="35" style={{ fontSize: "16px" }}>
                  <tspan x="20px" style={{ fontWeight: "bold" }}>
                    Bachelor of Software Engineering
                  </tspan>
                  <tspan x="20px" dy="1.2em" style={{ fontWeight: "bolder" }}>
                    McGill University | Montreal, QC{" "}
                  </tspan>
                </text>
              </svg>
            </Timeline.Item>
            <Timeline.Item position="left" label="May 2019 - Aug 2019">
              <svg
                width="451"
                height="200"
                viewBox="0 0 451 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M450.5 0H36.2192H0L36.2192 33V200H450.5V0Z"
                  fill="white"
                />
                <text fill="black" x="60px" y="20px">
                  <tspan
                    x="60px"
                    dy="1.2em"
                    style={{ fontSize: 16, fontWeight: "bold" }}
                  >
                    Front End Developer Intern
                  </tspan>
                  <tspan
                    x="60px"
                    dy="1.2em"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    Mnubo (Now Aspen Tech) | Montreal, QC
                  </tspan>
                  <tspan x="60px" dy="2.4em" style={{ fontSize: 14 }}>
                    • Migrated 10+ AngularJS modules to Angular 2, improving
                  </tspan>
                  <tspan x="60px" dy="1.2em" style={{ fontSize: 14 }}>
                    their readability and performance.
                  </tspan>
                  <tspan x="60px" dy="1.2em" style={{ fontSize: 14 }}>
                    • Designed and refactored 10+ test suites using Jasmine/Jest
                  </tspan>
                  <tspan x="60px" dy="1.2em" style={{ fontSize: 14 }}>
                    to increase code stability.
                  </tspan>
                  <tspan x="60px" dy="1.2em" style={{ fontSize: 14 }}>
                    • Worked with the front end team to identify and fix bugs
                  </tspan>
                  <tspan x="60px" dy="1.2em" style={{ fontSize: 14 }}>
                    found in existing components.
                  </tspan>
                </text>
              </svg>
            </Timeline.Item>
            <Timeline.Item position="right" label="June 2018 - Aug 2018">
              <svg
                width="451"
                height="200"
                viewBox="0 0 451 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0H414.281H450.5L414.281 33V200H0V0Z" fill="white" />
                <text fill="black" x="20px" y="25px">
                  <tspan
                    x="20px"
                    dy="1.2em"
                    style={{ fontSize: 16, fontWeight: "bold" }}
                  >
                    Front End Developer Intern
                  </tspan>
                  <tspan
                    x="20px"
                    dy="1.2em"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    McGill Centre for Integrative Neuroscience (MCIN)
                  </tspan>
                  <tspan
                    x="20px"
                    dy="1.2em"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    Montreal, QC
                  </tspan>
                  <tspan x="20px" dy="2.4em" style={{ fontSize: 14 }}>
                    • Developed user-friendly import/export feature in ReactJS
                    for
                  </tspan>
                  <tspan x="20px" dy="1.2em" style={{ fontSize: 14 }}>
                    a data visualization web app.
                  </tspan>
                  <tspan x="20px" dy="1.2em" style={{ fontSize: 14 }}>
                    • Created UI mockups to show the client during pre-project
                  </tspan>
                  <tspan x="20px" dy="1.2em" style={{ fontSize: 14 }}>
                    analysis meetings based on their requirements.
                  </tspan>
                </text>
              </svg>
            </Timeline.Item>
          </Timeline>
        </MediaQuery>
        <MediaQuery maxWidth={954}>
          <Timeline mode="alternate">
            <Timeline.Item position="right" label="Graduated Dec 2020">
              <div style={styles.itemContainerLeft}>
                <div style={styles.itemTextBox}>
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Bachelor of Software Engineering
                  </p>
                  <p
                    style={{
                      fontWeight: "bolder",
                      fontSize: "16px",
                      marginBottom: "0px",
                    }}
                  >
                    McGill University | Montreal, QC
                  </p>
                </div>
              </div>
            </Timeline.Item>
            <Timeline.Item
              position="left"
              label="May 2019 - Aug 2019"
              style={{ alignSelf: "flex-start" }}
            >
              <div style={styles.itemContainerRight}>
                <div style={styles.itemTextBox}>
                  <p style={{ fontSize: 16, fontWeight: "bold" }}>
                    Front End Developer Intern
                  </p>
                  <p style={{ fontSize: 16, fontWeight: "bolder" }}>
                    Mnubo (Now Aspen Tech) | Montreal, QC
                  </p>
                  <p style={{ fontSize: 14 }}>
                    • Migrated 10+ AngularJS modules to Angular 2, improving
                    their readability and performance.
                  </p>
                  <p style={{ fontSize: 14 }}>
                    • Designed and refactored 10+ test suites using Jasmine/Jest
                    to increase code stability.
                  </p>
                  <p style={{ fontSize: 14 }}>
                    • Worked with the front end team to identify and fix bugs
                    found in existing components.
                  </p>
                </div>
              </div>
            </Timeline.Item>
            <Timeline.Item position="right" label="June 2018 - Aug 2018">
              <div style={styles.itemContainerLeft}>
                <div style={styles.itemTextBox}>
                  <p style={{ fontSize: 16, fontWeight: "bold" }}>
                    Front End Developer Intern
                  </p>
                  <p style={{ fontSize: 16, fontWeight: "bolder" }}>
                    McGill Centre for Integrative Neuroscience (MCIN) |
                    Montreal, QC
                  </p>
                  <p style={{ fontSize: 14 }}>
                    • Developed user-friendly import/export feature in ReactJS
                    for a data visualization web app.
                  </p>
                  <p style={{ fontSize: 14 }}>
                    • Created UI mockups to show the client during pre-project
                    analysis meetings based on their requirements.
                  </p>
                </div>
              </div>
            </Timeline.Item>
          </Timeline>
        </MediaQuery>
      </div>
    </div>
  );
}

const styles = {
  experience: {
    paddingTop: "69px",
    marginTop: "-69px",
  },
  experienceContainer: {
    backgroundColor: "#B6DDE3",
    paddingBottom: "30px",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  title: {
    fontSize: "29px",
    marginBottom: "30px",
    paddingTop: "30px",
  },
  itemContainerLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  itemContainerRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  itemTextBox: {
    padding: "10px",
    backgroundColor: "#FFFFFF",
    width: "90%",
    textAlign: "initial",
    borderRadius: "5px",
    marginRight: "10px",
  },
};
export default Experience;
