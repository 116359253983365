import { Carousel } from "antd";
import me from "../assets/images/contact/me.jpg";
import filles from "../assets/images/contact/filles.jpg";
import heforshe from "../assets/images/contact/heforshe.jpg";
import skating from "../assets/images/contact/skating.jpg";
import speed from "../assets/images/contact/speed.jpg";
import linkedin from "../assets/images/contact/linkedin.svg";
import github from "../assets/images/contact/github.svg";
import resume from "../assets/images/contact/Diana_Serra_Resume.pdf";
function Contact() {
  return (
    <div id="Contact" style={styles.contact}>
      <div style={styles.contactContainer}>
        <h1 style={styles.title}>Contact Info</h1>
        <div style={styles.content}>
          <p>
            If you're seeing this, thanks for scrolling this far! I hope you got
            to know me a little bit - feel free to check out the links below or
            email me for more information.
          </p>
          <div style={{ marginBottom: "20px" }}>
            <Carousel autoplay>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={me}
                  style={styles.imgStyle}
                  alt="Diana smiling and looking to the side"
                ></img>
              </div>
              <div>
                <img
                  src={heforshe}
                  style={styles.imgStyle}
                  alt="Diana standing next to HeForShe Get Free Tour panelists around a HeForShe banner"
                ></img>
              </div>
              <div>
                <img
                  src={filles}
                  style={styles.imgStyle}
                  alt="Diana smiling and standing behind a table next to four other volunteers wearing POWE shirts at les filles et les sciences"
                ></img>
              </div>
              <div>
                <img
                  src={skating}
                  style={styles.imgStyle}
                  alt="Diana smiling and posing with 3 other women on an indoor ice rink"
                ></img>
              </div>
              <div>
                <img
                  src={speed}
                  style={styles.imgStyle}
                  alt="Diana posing with about 50 other people at a networking event"
                ></img>
              </div>
            </Carousel>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <a style={styles.mailLink} href="mailto:dianaiserra07@gmail.com">
              diana.isabel.serra@gmail.com
            </a>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <a style={styles.mailLink} href={resume} download>
              Download My Resume!
            </a>
          </div>
          <div>
            <a
              style={{ paddingRight: "15px" }}
              href="https://www.linkedin.com/in/diana-serra/"
            >
              <img
                src={linkedin}
                style={{ height: "45px" }}
                alt="linkedin logo"
              ></img>
            </a>
            <a href="https://github.com/DianaSerra">
              <img
                src={github}
                style={{ height: "45px" }}
                alt="github logo"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  contact: {
    paddingTop: "69px",
    marginTop: "-69px",
  },
  contactContainer: {
    backgroundColor: "#FFBE43",
    paddingTop: "30px",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "29px",
    marginBottom: "30px",
  },
  content: {
    width: "60%",
    maxWidth: "815px",
    fontSize: "16px",
    fontWeight: "bolder",
  },
  imgStyle: {
    maxHeight: "300px",
    width: "80%",
    display: "inline",
    borderRadius: "5px",
    objectFit: "contain",
  },
  mailLink: {
    color: "#000000",
    textDecoration: "underline",
  },
};
export default Contact;
