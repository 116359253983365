import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import TechnicalSkills from "./components/TechnicalSkills";
import Experience from "./components/Experience";
import Community from "./components/Community";
import Hobbies from "./components/Hobbies";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Header />
      <div style={styles.body}>
        <Home />
        <TechnicalSkills />
        <Experience />
        <Community />
        <Hobbies />
        <Contact />
      </div>
      <div style={{ padding: "40px" }}>
        <div>
          Icons made by{" "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>
    </div>
  );
}
const styles = {
  body: {
    paddingTop: "70px",
    maxWidth: "100%",
    overflowX: "hidden",
  },
};
export default App;
