import computer from "../assets/images/skills/computer.svg";
import MediaQuery from "react-responsive";

function TechnicalSkills() {
  return (
    <div id="Skills" style={styles.technicalSkillsContainer}>
      <MediaQuery minWidth={480}>
        <div style={{ paddingTop: "30px" }}>
          <h1 style={styles.title}>Technical Skills</h1>
          <div style={styles.listContainer}>
            <div style={styles.technicalSkills}>
              <div style={styles.text}>
                <ul style={styles.skills}>
                  <li>ReactJS/ReactNative</li>
                  <li>Angular</li>
                  <li>Python</li>
                  <li>Java</li>
                  <li>C</li>
                  <li>SQL/PostgreSQL</li>
                  <li>Node.js</li>
                  <li>Javascript/ES6</li>
                  <li>HTML/CSS</li>
                  <li>Git</li>
                  <li>Unit testing (Jasmine, Jest, JUnit)</li>
                </ul>
              </div>
              <div style={styles.computerContainer}>
                <img
                  src={computer}
                  alt="Cartoon computer"
                  style={styles.computer}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={479}>
        <div style={{ paddingTop: "30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={computer}
              alt="Cartoon computer"
              style={styles.computerSmall}
            ></img>
            <h1 style={styles.title}>Technical Skills</h1>
            <img
              src={computer}
              alt="Cartoon computer"
              style={styles.computerSmall}
            ></img>
          </div>
          <div style={styles.listContainer}>
            <div style={styles.technicalSkills}>
              <div style={styles.textMobile}>
                <ul style={styles.skills}>
                  <li>ReactJS/ReactNative</li>
                  <li>Angular</li>
                  <li>Python</li>
                  <li>Java</li>
                  <li>C</li>
                  <li>SQL/PostgreSQL</li>
                  <li>Node.js</li>
                  <li>Javascript/ES6</li>
                  <li>HTML/CSS</li>
                  <li>Git</li>
                  <li>Unit testing (Jasmine, Jest, JUnit)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
}
const styles = {
  technicalSkillsContainer: {
    paddingTop: "68px",
    marginTop: "-68px",
  },
  listContainer: {
    paddingRight: "15px",
    paddingLeft: "15px",
    display: "flex",
    justifyContent: "center",
  },
  technicalSkills: {
    display: "flex",
  },
  text: {
    width: "50%",
    margin: "30px",
  },
  title: {
    fontSize: "29px",
    textAlign: "center",
    marginBottom: "0px",
  },
  skills: {
    fontSize: "18px",
    textAlign: "left",
    paddingLeft: "0px",
  },
  computerContainer: {
    display: "flex",
    width: "50%",
    margin: "30px",
  },
  computer: {
    width: "100%",
  },
  textMobile: {
    margin: "30px",
  },
  computerSmall: {
    paddingRight: "15px",
    paddingLeft: "15px",
    height: "30px",
  },
};
export default TechnicalSkills;
