import music from "../assets/images/hobbies/music.svg";
import theater from "../assets/images/hobbies/theater.svg";
import other from "../assets/images/hobbies/other.svg";
import MediaQuery from "react-responsive";

function Hobbies() {
  function musicText() {
    return (
      <div style={styles.hobbiesText}>
        <p>
          <span>Music has been a key* part of my life since I started </span>
          <span style={{ fontWeight: "bold" }}>piano </span>
          <span>
            lessons at 8 years old. From that point on, I was completely hooked;
            learning to play the{" "}
          </span>
          <span style={{ fontWeight: "bold" }}>
            tenor saxophone, alto saxophone, ukulele,{" "}
          </span>
          <span>and realizing my love for </span>
          <span style={{ fontWeight: "bold" }}>singing. </span>
        </p>
        <p style={{ fontStyle: "italic", marginBottom: "0px" }}>
          * pun intended
        </p>
      </div>
    );
  }
  function theaterText() {
    return (
      <div style={styles.hobbiesText}>
        <p>
          <span>
            Immersed in the world of music, I was of course introduced to
            musicals- and from this, Theater Geek Diana was born! Years of
            acting classes, musical theater camps, dance classes and voice
            lessons later, my love for broadway never diminished. Today you can
            still find me{" "}
          </span>
          <span style={{ fontWeight: "bold", marginBottom: "0px" }}>
            listening to musical cast recordings, and nerding out over Tony
            nominations.
          </span>
        </p>
      </div>
    );
  }
  function hobbiesText() {
    return (
      <div style={styles.hobbiesText}>
        <p style={{ marginBottom: "0px" }}>
          <span>During the first COVID lockdown in March 2020, I started </span>
          <span style={{ fontWeight: "bold" }}>
            practicing the guitar every day
          </span>
          <span>
            , and have been keeping up the habit ever since. Additionally, I’m
            learning how to use a sewing machine, with the goal of eventually{" "}
          </span>
          <span style={{ fontWeight: "bold" }}>
            making and altering my own clothing
          </span>
          <span>, thereby reducing my carbon footprint! </span>
        </p>
      </div>
    );
  }
  return (
    <div id="Hobbies" style={styles.outerHobbiesContainer}>
      <div style={styles.hobbiesContainer}>
        <h1 style={styles.title}>Hobbies & Special Interests</h1>
        <MediaQuery minWidth={701}>
          <div style={styles.hobbies}>
            <img
              style={styles.hobbiesImage}
              src={music}
              alt="images of piano and microphone"
            ></img>
            {musicText()}
          </div>
          <div style={styles.hobbies}>
            {theaterText()}
            <img
              style={styles.hobbiesImage}
              src={theater}
              alt="images of a theater and masks"
            ></img>
          </div>
          <div style={styles.hobbies}>
            <img
              style={styles.hobbiesImage}
              src={other}
              alt="images of sewing machine and guitar"
            ></img>
            {hobbiesText()}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={700}>
          <div style={styles.hobbiesSmall}>
            <img
              style={styles.hobbiesImage}
              src={music}
              alt="images of piano and microphone"
            ></img>
            {musicText()}
            <img
              style={styles.hobbiesImage}
              src={theater}
              alt="images of a theater and masks"
            ></img>
            {theaterText()}
            <img
              style={styles.hobbiesImage}
              src={other}
              alt="images of sewing machine and guitar"
            ></img>
            {hobbiesText()}
          </div>
        </MediaQuery>
      </div>
    </div>
  );
}

const styles = {
  outerHobbiesContainer: {
    paddingTop: "68px",
    marginTop: "-68px",
  },
  hobbiesContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "29px",
    marginBottom: "30px",
  },
  hobbiesText: {
    fontSize: "16px",
    textAlign: "left",
    flex: 2,
  },
  hobbies: {
    display: "flex",
    justifyContent: "flex-start",
    width: "60%",
    maxWidth: "815px",
    paddingBottom: "10px",
  },
  hobbiesImage: {
    paddingRight: "30px",
    paddingLeft: "30px",
    paddingTop: "10px",
    paddingBottom: "10px",
    flex: 1,
    maxHeight: "140px",
    alignSelf: "center",
  },
  hobbiesSmall: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "60%",
  },
};
export default Hobbies;
