import React, { useState } from "react";
import preston from "../assets/images/community/PrestonPhipps.jpg";
import heforshe from "../assets/images/community/heforshe.jpg";
import swe from "../assets/images/community/SWE19.jpg";
import tabling from "../assets/images/community/tabling.jpg";
import MediaQuery from "react-responsive";

//TODO: do an overlay over the card images rather than the expanding thing
function Community() {
  const [prestonDescription, setPrestonDescription] = useState(0);
  const [sweDescription, setSweDescription] = useState(0);
  const [heforsheDescription, setHeforsheDescription] = useState(0);
  const [tablingDescription, setTablingDescription] = useState(0);

  function prestonPhipps(small) {
    const width = small ? 50 : 25;
    return (
      <div
        style={{ ...styles.imageContainer, width: `${width}%` }}
        onMouseOver={() => setPrestonDescription(350)}
        onMouseOut={() => setPrestonDescription(0)}
      >
        <img
          src={preston}
          style={styles.image}
          alt="Diana being handed a plaque by the Dean of McGill Engineering"
        ></img>

        <div
          style={{
            ...styles.overlay,
            height: `${prestonDescription}px`,
          }}
        >
          <div style={styles.overlayText}>
            <p style={styles.overlayTitle}>
              Preston Phipps Equity and Diversity Award
            </p>
            <p style={styles.overlayBody}>
              In May 2019 I had the honor of accepting the Preston Phipps Award
              on behalf of POWE for the diversity and inclusion work we have
              done within the McGill Engineering community.
            </p>
          </div>
        </div>
      </div>
    );
  }
  function sweImage(small) {
    const width = small ? 50 : 25;
    return (
      <div
        style={{ ...styles.imageContainer, width: `${width}%` }}
        onMouseOver={() => setSweDescription(350)}
        onMouseOut={() => setSweDescription(0)}
      >
        <img
          src={swe}
          style={styles.image}
          alt="Diana and two other women standing in front of a SWE backdrop"
        ></img>
        <div
          style={{
            ...styles.overlay,
            height: `${sweDescription}px`,
          }}
        >
          <div style={styles.overlayText}>
            <p style={styles.overlayTitle}>
              Society of Women Engineers Conference 2019
            </p>
            <p style={styles.overlayBody}>
              In December of 2019 I was sent as a POWE delegate to the Society
              of Women Engineers (SWE) conference in Anaheim, California. The
              other delegates and I attended workshops focused on career
              advancement and improving the gender gap in Engineering.
            </p>
          </div>
        </div>
      </div>
    );
  }
  function heforsheImage(small) {
    const width = small ? 50 : 25;
    return (
      <div
        style={{ ...styles.imageContainer, width: `${width}%` }}
        onMouseOver={() => setHeforsheDescription(350)}
        onMouseOut={() => setHeforsheDescription(0)}
      >
        <img
          src={heforshe}
          style={styles.image}
          alt="Diana standing next to a HeForShe banner"
        ></img>
        <div
          style={{
            ...styles.overlay,
            height: `${heforsheDescription}px`,
          }}
        >
          <div style={styles.overlayText}>
            <p style={styles.overlayTitle}>
              HeForShe #GetFreeTour 2019 Panelist
            </p>
            <p style={styles.overlayBody}>
              As part of the HeForShe #GetFreeTour, I sat on a Panel of 3 other
              activists and specialists on gender diversity and inclusion to
              speak about allyship from my perspective as a woman studying
              engineering.
            </p>
          </div>
        </div>
      </div>
    );
  }
  function tablingImage(small) {
    const width = small ? 50 : 25;
    return (
      <div
        style={{ ...styles.imageContainer, width: `${width}%` }}
        onMouseOver={() => setTablingDescription(350)}
        onMouseOut={() => setTablingDescription(0)}
      >
        <img
          src={tabling}
          style={styles.image}
          alt="Diana shrugging quirkly behind an outdoor table with POWE swag"
        ></img>
        <div
          style={{
            ...styles.overlay,
            height: `${tablingDescription}px`,
          }}
        >
          <div style={styles.overlayText}>
            <p style={styles.overlayTitle}>
              Community Building and Outreach Events
            </p>
            <p style={styles.overlayBody}>
              Though I can't include every community outreach event I attended
              over the years, being at these events and getting to speak to
              hundreds of students about engineering and gender diversity were
              the best part of being involved in the McGill Engineering
              community!
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="Community" style={styles.community}>
      <div style={styles.communityContainer}>
        <h1 style={styles.title}>Community Engagement & Leadership</h1>
        <div style={styles.communityText}>
          <p>
            Taking part in my community and standing for causes I believe in are
            integral parts of who I am. When I entered the field of engineering,
            I knew this meant making it easier for other women and members of
            marginalized communities to pursue careers in STEM.
          </p>
          <p>
            In 2016, I got involved with Promoting Opportunities for Women in
            Engineering (POWE McGill) and I never looked back! Throughout the
            years I managed mentorship programs, planned endless events and
            workshops, and volunteered whenever I could.
          </p>
          <p>
            In 2019-2020, I became one of the Presidents of POWE and had the
            privilege of planning weekly general meetings, speaking on behalf of
            the organization at public events, and providing my team members
            with the support they needed to run their events and programs.
          </p>
          <p>
            Today, I remain an active member of the POWE Advisory Board, and
            hope to continue being a leader in every community I join. Check out
            some of my hightlights from the past few years below!
          </p>
        </div>
        <MediaQuery minWidth={900}>
          <div style={styles.highlightsContainer}>
            {prestonPhipps(false)}
            {sweImage(false)}
            {heforsheImage(false)}
            {tablingImage(false)}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={899}>
          <div style={styles.highlightsContainerSmall}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {prestonPhipps(true)}
              {sweImage(true)}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {heforsheImage(true)}
              {tablingImage(true)}
            </div>
          </div>
        </MediaQuery>
      </div>
    </div>
  );
}
const styles = {
  community: {
    paddingTop: "69px",
    marginTop: "-69px",
  },
  communityContainer: {
    backgroundColor: "#0B2412",
    paddingTop: "30px",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "29px",
    marginBottom: "30px",
    color: "#FFFFFF",
  },
  communityText: {
    width: "70%",
    maxWidth: "815px",
    fontSize: "16px",
    color: "#FFFFFF",
  },
  highlightsContainer: {
    width: "80%",
    display: "flex",
  },
  imageContainer: {
    position: "relative",
    minWidth: "160px",
    margin: "10px",
    cursor: "pointer",
  },
  image: {
    display: "block",
    width: "100%",
    height: "350px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  overlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "#C3DCCA",
    overflow: "auto",
    width: "100%",
    transition: ".5s ease",
    borderRadius: "5px",
  },
  overlayText: {
    color: "black",
    position: "absolute",
    width: "80%",
    left: "50%",
    paddingTop: "15px",
    paddingBottom: "15px",
    WebkitTransform: "translate(-50%, 0)",
    transform: "translate(-50%, 0)",
  },
  overlayTitle: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bolder",
  },
  overlayBody: {
    fontSize: "14px",
    textAlign: "center",
  },
  highlightsContainerSmall: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
  },
};

export default Community;
